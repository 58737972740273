import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'

import Layout from '../components/layout/Layout.en'
import Seo from '../components/Seo'
import Slide from '../components/slide/Slide'
import SlideItem from '../components/slide/SlideItem'
import Reason from '../components/reason/Reason'
import ReasonItem from '../components/reason/ReasonItem'
import SlideTutorial from '../components/slide-tutorial/SlideTutorial'
import SlideTutorialItem from '../components/slide-tutorial/SlideTutorialItem'
import ContactForm from '../components/contact-form/ContactForm'

import useSiteMetadata from '../hooks/useSiteMetadata'

import thumbnailImage from '../images/warpin-favicon.png'

const IklanPintar = ({ data, location }) => {
	const [hash, setHash] = useState(location?.hash)
	const { siteUrl } = useSiteMetadata()

	const sliderData = data.wpgHeadlessQuery.acfSlideBanner.slideBannerItem
	const seoData = data.wpgHeadlessQuery.seo

	console.log(sliderData)

	return (
		<Layout
			location={location}
			logo={data.logoImage}
			setHash={setHash}
			hash={hash}
		>
			<Seo
				title={seoData.title}
				canonical={`${siteUrl}${location.pathname}`}
				meta={[
					{ name: 'description', content: seoData.metaDesc },
					{ name: 'keywords', content: seoData.metaKeywords },
					{
						name: 'robots',
						content: `${seoData.metaRobotsNoindex}, ${seoData.metaRobotsNofollow}`,
					},
					{
						property: 'og:url',
						content: `${siteUrl}${location.pathname}`,
					},
					{ property: 'og:title', content: seoData.opengraphTitle },
					{
						property: 'og:description',
						content: seoData.opengraphDescription,
					},
					{ property: 'og:type', content: seoData.opengraphType },
					{
						property: 'og:site_name',
						content: seoData.opengraphSiteName,
					},
					{
						property: 'og:image',
						content:
							seoData.opengraphImage !== null
								? seoData.opengraphImage.localFile.publicURL
								: thumbnailImage,
					},
					{ name: 'twitter:title', content: seoData.twitterTitle },
					{
						name: 'twitter:description',
						content: seoData.twitterDescription,
					},
					{
						name: 'twitter:image',
						content:
							seoData.twitterImage !== null
								? seoData.twitterImage.localFile.publicURL
								: thumbnailImage,
					},
					{ name: 'twitter:card', content: 'summary' },
					{ name: 'twitter:site', content: '@warungpintarco' },
				]}
			/>

			<section className='slide-banner'>
				<Slide>
					{sliderData.map((itemData, index) => (
						<SlideItem
							key={index}
							title={itemData.title}
							content={itemData.content}
							buttonLabel={itemData.buttonLabel}
							buttonLink={itemData.buttonLink}
							logoSrc={getImage(itemData.logoSrc.localFile)}
							imageSrc={getImage(itemData.imageSrc.localFile)}
							imageXsSrc={getImage(itemData.imageXsSrc.localFile)}
							withButton={itemData.withButton}
							withLogo={true}
							logoSlim={true}
							bgPosition='center bottom'
						/>
					))}
				</Slide>
			</section>

			<section className='reason' id='alasan'>
				<Reason title='WHY Iklan Pintar?'>
					<ReasonItem
						title='Efficient'
						content='<strong>Single gateway</strong> in reaching multiple warungs through <strong>both offline and online channel</strong>'
						imageSrc={getImage(data.reasonImage.nodes[0])}
						imageAlt='wpg-illustrator'
					/>
					<ReasonItem
						title='Targeted Campaign'
						content='<strong>Customized campaign according to brand’s objective</strong>. Customization includes demographic, warung type and the surrounding areas'
						imageSrc={getImage(data.reasonImage.nodes[1])}
						imageAlt='wpg-illustrator'
					/>
					<ReasonItem
						title='Transparent'
						content='<strong>Clear visibility</strong> on the operational process and <strong>flexibility to iterate</strong> campaign content according to brand’s needs <strong>based on real-time data</strong>'
						imageSrc={getImage(data.reasonImage.nodes[2])}
						imageAlt='wpg-illustrator'
					/>
					<ReasonItem
						title='Reliable segment insights'
						content='<strong>Optimize product development</strong> and create a <strong>tailor-made campaign</strong>'
						imageSrc={getImage(data.reasonImage.nodes[3])}
						imageAlt='wpg-illustrator'
					/>
				</Reason>
			</section>

			<section className='tutorial-1' id='audiens'>
				<SlideTutorial
					title='reach Your audience'
					subtitle='1. reach <strong>warung owners</strong>'
					text='Online Advertising & in app Activation '
					content='Warung owners are the decision maker to which products will be sold in their warung and have an influence within their community. '
					bgColor='#FECF28'
				>
					<SlideTutorialItem
						title='Official Store on Warung Pintar App'
						content='Exclusive brand exposure on the main page of Warung Pintar app'
						imageSrc={getImage(data.slideTutorial.nodes[0])}
						imageAlt='wpg-illustrator'
					/>
					<SlideTutorialItem
						title='Category Representatives on Warung Pintar App'
						content='Brand exposure on the product category in Warung Pintar app'
						imageSrc={getImage(data.slideTutorial.nodes[1])}
						imageAlt='wpg-illustrator'
					/>
					<SlideTutorialItem
						title='Digital Ads on Warung Pintar App'
						content='User brand awareness  via Warung Pintar app'
						imageSrc={getImage(data.slideTutorial.nodes[2])}
						imageAlt='wpg-illustrator'
					/>
					<SlideTutorialItem
						title='WhatsApp Blast'
						content='Direct communication to reach Juragan through daily used channel'
						imageSrc={getImage(data.slideTutorial.nodes[3])}
						imageAlt='wpg-illustrator'
					/>
					<SlideTutorialItem
						title='WhatsApp Sticker'
						content='Fun way to broaden brand marketing channels '
						imageSrc={getImage(data.slideTutorial.nodes[4])}
						imageAlt='wpg-illustrator'
					/>
					<SlideTutorialItem
						title='Gamification on Warung Pintar App'
						content='Boost regular SKU sales through gamified scheme and benefit loyal user'
						imageSrc={getImage(data.slideTutorial.nodes[5])}
						imageAlt='wpg-illustrator'
					/>
				</SlideTutorial>
			</section>

			<section className='tutorial-2'>
				<SlideTutorial
					subtitle='2. reach <strong>end-consumer</strong>'
					text='Warung’s Offline Assets and Activation'
					content='Directly get in touch with your end-consumers.   Accommodated using our service, advertisers will be able to see campaign insight afterward.'
					bgColor='#FFEFBB'
				>
					<SlideTutorialItem
						title='Offline promotion Assets in Warung '
						content='Main attraction for your customers when they enter warung. Also comes with a  reliable daily report'
						imageSrc={getImage(data.slideTutorial.nodes[6])}
						imageAlt='wpg-illustrator'
					/>
					<SlideTutorialItem
						title='Product Sampling in Warung '
						content='Introduce your products directly to the end-consumer and receive an immediate product insights'
						imageSrc={getImage(data.slideTutorial.nodes[7])}
						imageAlt='wpg-illustrator'
					/>
					<SlideTutorialItem
						title='Visibility on Warung Pintar Social Media'
						content='Spreading information from brand / principal through Warung Pintar Facebook / IG / Blogpost / Newsletter'
						imageSrc={getImage(data.slideTutorial.nodes[8])}
						imageAlt='wpg-illustrator'
					/>
					<SlideTutorialItem
						title='Visibility  on Warung Pintar Official Store'
						content='Spreading information from brand / principal through Warung Pintar Official Store in ecommerce'
						imageSrc={getImage(data.slideTutorial.nodes[9])}
						imageAlt='wpg-illustrator'
					/>
					<SlideTutorialItem
						title='warung Refferal Activation campaign'
						content='Create awareness towards the service. Bring new channel for referral campaign'
						imageSrc={getImage(data.slideTutorial.nodes[10])}
						imageAlt='wpg-illustrator'
					/>
				</SlideTutorial>
			</section>

			<section className='contact-form'>
				<ContactForm
					title='STILL GOT QUESTIONS?'
					subtitle='Contact us and let ’s discuss what we can help to boost your business!'
					endpoint='https://getform.io/f/e403cc8a-9b83-4ef4-8af7-cd86c7a99c78'
					lang={true}
					isBFF={true}
					imageSrc={getImage(data.contactBackgrounds.nodes[3])}
					imageXsSrc={getImage(data.contactBackgrounds.nodes[7])}
					maxWidth={true}
				/>
			</section>
		</Layout>
	)
}

export const queryData = graphql`
	{
		wpgHeadlessQuery(slug: { eq: "iklan-pintar-en" }) {
			id
			slug
			seo {
				canonical
				title
				metaDesc
				metaKeywords
				metaRobotsNofollow
				metaRobotsNoindex
				opengraphDescription
				opengraphSiteName
				opengraphTitle
				opengraphType
				opengraphUrl
				opengraphImage {
					localFile {
						publicURL
					}
				}
				twitterTitle
				twitterDescription
				twitterImage {
					localFile {
						publicURL
					}
				}
			}
			acfSlideBanner {
				slideBannerItem {
					buttonLabel
					buttonLink
					content
					title
					withButton
					logoSrc {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1200)
							}
						}
					}
					imageXsSrc {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1200)
							}
						}
					}
					imageSrc {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1600)
							}
						}
					}
				}
			}
		}
		reasonImage: allFile(
			filter: { relativeDirectory: { eq: "illustrations/reason" } }
			sort: { order: ASC, fields: [name] }
		) {
			nodes {
				childImageSharp {
					gatsbyImageData(width: 600)
				}
			}
		}
		slideTutorial: allFile(
			filter: {
				relativeDirectory: { eq: "illustrations/slide-tutorial" }
			}
			sort: { order: ASC, fields: [name] }
		) {
			nodes {
				childImageSharp {
					gatsbyImageData(width: 600)
				}
			}
		}
		contactBackgrounds: allFile(
			filter: { relativeDirectory: { eq: "illustrations/contact-forms" } }
			sort: { order: ASC, fields: [name] }
		) {
			nodes {
				childImageSharp {
					gatsbyImageData(width: 1440)
				}
			}
		}
		logoImage: file(relativePath: { eq: "warpin-logo-inverse.png" }) {
			childImageSharp {
				gatsbyImageData(width: 800)
			}
		}
	}
`

export default IklanPintar
